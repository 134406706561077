<template>
  <div class="page-container">
    <van-cell-group title="个人信息">
      <template v-if="!editInfo">
        <van-cell title="微信名称" :value="user.name" />
        <van-cell title="姓名" :value="user.realname" />
        <van-cell title="性别" :value="user.sex ? `女` : `男`" />
        <van-cell title="联系电话" :value="user.phone" />
        <van-cell title="邮箱" :value="user.email" />
        <van-cell title="与学校关系" :value="user.relation" />
        <van-cell title="注册时间" :value="user.signin" />
      </template>
      <template v-else>
        <van-cell title="微信名称" :value="user.name" />
        <van-field
          v-model="user.realname"
          label="姓名"
          placeholder="填写您的姓名"
        />
        <van-field label="性别">
          <template #input>
            <van-radio-group v-model="user.sex" direction="horizontal">
              <van-radio :name="0">男</van-radio>
              <van-radio :name="1">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="user.phone"
          label="联系电话"
          placeholder="填写联系电话"
        />
        <van-field
          v-model="user.email"
          label="邮箱"
          placeholder="填写电子邮箱"
        />

        <van-field
          readonly
          clickable
          name="picker"
          :value="user.relation"
          label="与学校关系"
          placeholder="点击选择"
          @click="showRelationPicker = true"
        />
        <van-popup v-model="showRelationPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="relationPickerColumns"
            @confirm="handleRelationPicker"
            @cancel="showRelationPicker = false"
          />
        </van-popup>
        <van-cell title="注册时间" :value="user.signin" />
      </template>
      <van-cell>
        <template #title>
          <van-button
            class="profile-edit-button"
            type="info"
            :plain="!editInfo"
            size="mini"
            @click="handleEditInfo"
            >{{ editInfo ? "保存个人信息" : "编辑个人信息" }}</van-button
          >
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="工作信息">
      <template v-if="!editWork">
        <van-cell title="工作单位" :value="user.company" />
        <van-cell title="职务" :value="user.occupation" />
      </template>
      <template v-else>
        <van-field
          v-model="user.company"
          label="工作单位"
          placeholder="填写工作单位"
        />
        <van-field
          v-model="user.occupation"
          label="职务"
          placeholder="填写您的职务"
        />
      </template>
      <van-cell>
        <template #title>
          <van-button
            class="profile-edit-button"
            type="info"
            :plain="!editWork"
            size="mini"
            @click="handleEditWork"
            >{{ editWork ? "保存工作信息" : "编辑工作信息" }}</van-button
          >
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="在校信息">
      <template v-if="!editStu">
        <van-cell title="校名" :value="user.school" />
        <van-cell title="学院" :value="user.college" />
        <van-cell title="专业" :value="user.major" />
        <van-cell title="班级" :value="user.class" />
        <van-cell title="入校时间" :value="user.inschoolyear" />
      </template>
      <template v-else>
        <van-field
          v-model="user.school"
          label="校名"
          placeholder="填写学校名称"
        />
        <van-field
          v-model="user.college"
          label="学院"
          placeholder="填写学院名称"
        />
        <van-field
          v-model="user.major"
          label="专业"
          placeholder="填写您的专业"
        />
        <van-field
          v-model="user.class"
          label="班级"
          placeholder="填写您的班级"
        />
        <van-field
          v-model="user.inschoolyear"
          label="入校时间"
          placeholder="填写入校时间"
        />
      </template>
      <van-cell>
        <template #title>
          <van-button
            class="profile-edit-button"
            type="info"
            :plain="!editStu"
            size="mini"
            @click="handleEditStu"
            >{{ editStu ? "保存在校信息" : "编辑在校信息" }}</van-button
          >
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell>
      <template #title>
        <van-button
          class="profile-edit-button"
          type="danger"
          plain
          size="mini"
          @click="handleDeleteProfile"
          >删除所有信息</van-button
        >
      </template>
    </van-cell>
  </div>
</template>

<script>
import {
  getMyProfileData,
  updateMyProfileData,
  deleteMyProfileData,
} from "@/api/mine";
import { Dialog } from "vant";

export default {
  data() {
    return {
      user: {
        name: null,
        phone: null,
        email: null,
        signin: null,
      },
      editInfo: false,
      editWork: false,
      editStu: false,
      showRelationPicker: false,
      relationPickerColumns: ["校友", "在校生", "职工", "友人", "其他"],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getMyProfileData().then((response) => {
        this.user = response;
      });
    },
    handleEditInfo() {
      if (this.editInfo) {
        updateMyProfileData(this.user).then((response) => {
          console.log(response);
          this.editInfo = false;
          this.fetchData();
        });
      } else {
        this.editInfo = true;
      }
    },
    handleRelationPicker(val) {
      this.user.relation = val;
      this.showRelationPicker = false;
    },
    handleEditWork() {
      if (this.editWork) {
        updateMyProfileData(this.user).then((response) => {
          console.log(response);
          this.editWork = false;
          this.fetchData();
        });
      } else {
        this.editWork = true;
      }
    },
    handleEditStu() {
      if (this.editStu) {
        updateMyProfileData(this.user).then((response) => {
          console.log(response);
          this.editStu = false;
          this.fetchData();
        });
      } else {
        this.editStu = true;
      }
    },
    handleDeleteProfile() {
      Dialog.confirm({
        title: "删除所有信息",
      })
        .then(() => {
          deleteMyProfileData().then(() => {
            this.fetchData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.profile-edit-button {
  width: 100% !important;
}
</style>
